import "../style/app.css";
import "../style/daily.css"
import React, { Component } from 'react';
import Pubblicita from "./Pubblicita.js";
import axios from "axios";
import { HOST } from '../costanti.js';

class ElencoNewYear extends Component {

    constructor(props) {
        super(props);
        this.state = {
            risultati: [],
            anno: 0,
            isAdmin: sessionStorage.getItem("admin"),
            conteggioAdv: 1
        };
    }

    componentDidMount() {
        this.loadOroscopoAnnuale()
    }

    componentDidUpdate(_, prevState) {

        // if (this.state.filtro !== prevState.filtro) {
        //     this.loadOroscopoAnnuale()
        // }
    }


    loadOroscopoAnnuale() {

        this.setState({
            risultati: []
        })

        var myUrl = HOST + '/getNewYear.php'

        
        axios({
            method: 'GET',
            url: myUrl,
            headers: { 'content-type': 'application/json' }
        })
            .then(response => {
                var rows = []

                if (response.data.return) {

                    for (let i = 0; i < response.data.anno.length; i++) {

                        rows.push({ id: response.data.anno[i].id_oroscopo, anno: response.data.anno[i].anno, segno: response.data.anno[i].segno, descrizione_fortuna: response.data.anno[i].descrizione_fortuna, descrizione_amore: response.data.anno[i].descrizione_amore, descrizione_lavoro: response.data.anno[i].descrizione_lavoro, icona: response.data.anno[i].icona })
                    }
                    this.setState({
                        risultati: rows,
                        anno: rows[0].anno
                    })
                } 
            })
            .catch(error => alert(error));
    }

    render() {
        if (this.state.risultati.length === 0) {
            return (
                <div>
                    <h4>Le stelle non sono ancora ispirate...</h4>
                    <div>
                        <button type="button" className="btnDaily" onClick={() => this.loadOroscopoAnnuale()}>Prova di nuovo</button>
                    </div>
                </div>
            );
        } else {
            let conta = 0
            return (
                <div>
                    <div>
                        <p className="news">NUOVO ANNO {this.state.anno}</p>
                    </div>
                    <div>

                        {

                            this.state.risultati.map((object, index) => {

                                conta += 1
                                return (
                                    <div>
                                        <li key={index}>
                                            <div className="iconaSegno">
                                                <img src={require(`../assets/icons/${object.icona}`)} alt='' width={64} height={64} />
                                                <span className="segno">{object.segno}</span>
                                            </div>

                                            <div className="divOroscopo">
                                                <p>{object.descrizione_amore}</p>
                                                <div>
                                                    <h4>Lavoro</h4>
                                                    <p>{object.descrizione_lavoro}</p>
                                                </div>
                                                <div>
                                                    <h4>Amore</h4>
                                                    <p>{object.descrizione_fortuna}</p>
                                                </div>
                                            </div>



                                        
                                        </li>

                                        {
                                            conta % 2 === 0 ?
                                                <Pubblicita />
                                                :
                                                <div></div>
                                        }
                                    </div>
                                );

                            }
                            )}


                    </div>

                    <div>
                        <button type="button" className="btnDaily" onClick={() => this.loadOroscopoAnnuale()}>Prova di nuovo</button>
                    </div>

                </div>
            );
        }
    }

}

export default ElencoNewYear;
