import React, { Component } from 'react';
import axios from 'axios';
import '../style/app.css';
import '../style/accedi.css';
import '../style/input.css';
import { HOST } from '../costanti.js';
import { Link } from 'react-router-dom';
import Pubblicita from './Pubblicita.js';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            message: <div></div>
        };
    }

    tryLogin(event) {
        event.preventDefault();
        this.setState({
            message: <div></div>
        })

        axios({
            method: 'post',
            url: HOST + '/login.php',
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(response => {
                if (response.data.login) {
                    sessionStorage.setItem("username", response.data.utente)
                    sessionStorage.setItem("admin", response.data.ruolo)
                    sessionStorage.setItem("isUserLogged", true)
                    this.forceUpdate()

                } else {
                    this.setState({
                        message: <div><p style={{ color: "#d90a0a"}}><b>{response.data.message}</b></p></div>,
                    })
                }
            })
            .catch(error => this.setState({ message: <div><p>{error.message}</p></div> }));
    }

    //how to send data from react to php api
    //install axios using npm, it works well with http requests

    deleteSession() {

        sessionStorage.removeItem("username")
        sessionStorage.removeItem("isUserLogged")
        sessionStorage.removeItem("admin")

        this.setState({
            username: "",
            password: "",
            message: <div></div>
        })

        this.forceUpdate()
    }

   
    render() {
        var usernameEsiste = sessionStorage.getItem("username")
        var utenteLoggato = sessionStorage.getItem("isUserLogged")

        if (usernameEsiste === null && utenteLoggato === null ){

            return (

                <div className="form-login">
                    <p>Username</p>
                    <input type="text" name="username" className="username" 
                        value={this.state.username}
                        onChange={(e) => this.setState({ username: e.target.value })}
                        required />
                    <p>Password</p>
                    <input type="password" name="password" className="password" 
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        required/>
                    <button type="button" className="btnLogin" onClick={(e) => this.tryLogin(e)}>
                        LOGIN</button>


                {this.state.message}
                </div>

            );

            
        } else {

            return (
                <div className="menu">
                    <h2>Ciao {usernameEsiste}!</h2>
                    <Link to="/crea" style={{ textDecoration: 'none'}}>
                        <button type="button" className="btnMenu">
                           CREA L'OROSCOPO
                        </button>
                    </Link>
                    <button type="button" className="btnMenu btnLogout" onClick={() => this.deleteSession()}>
                        LOGOUT</button>
                    <Pubblicita />
                </div>
            );
            
        }
    
    }
}
export default Login;