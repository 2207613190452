import React from "react";
import '../style/app.css';
import '../style/info.css';
import package_json from "../../package.json"
import Pubblicita from "../components/Pubblicita";


function Info() {
    return (
        <div>
        <div className="info-div">
            <h4>Informazioni varie</h4>
            <h5>Nuova versione!</h5>
            <div className="info-flex">
                <h5>V {package_json.version} </h5>
                <span className="info-lista">Banner pubblicitari</span>
            </div>

            <div className="info-flex">
                <h5>V 1.6.1 </h5>
                <span className="info-lista">Stile degli oroscopi</span>
            </div>

            <div className="info-flex">
                <h5>V 1.6.0 </h5>
                <span className="info-lista">Si possono cancellare gli oroscopi sbagliati 😊</span>
            </div>
                
            <div className="info-flex">
                <h5>V 1.5.0</h5>
                <span className="info-lista">Questa nuova pagina!</span>
                <span className="info-lista">Footer più piccolo!</span>
                <span className="info-lista">Controlli per il completamento di tutti i campi!</span>
            </div>
            
           
        </div>
         <Pubblicita />
         </div>
    );
}

export default Info;