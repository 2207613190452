import "../style/footer.css";
import React from "react";
import { Link } from "react-router-dom";
import package_json from "../../package.json";

function Footer() {

    return (
        <footer>
            <p>
                Made with ❤
                <br />ReactJS
                <br />2023
                <br />{package_json.version}
            </p>
            <p>
                <Link to="/info" className="link-footer">
                   Info versione
                </Link>
            </p>
            <br />
            <br />
            
        </footer>
    );
}

export default Footer;