import React from "react";
import '../style/app.css';
import '../style/daily.css';
import ElencoNewYear from '../components/ElencoNewYear.js'
import Pubblicita from "../components/Pubblicita.js";


function NewYear() {
    return (
        <div className="daily">
            <h1>OROSCOPO DEL NUOVO ANNO</h1>
            <ElencoNewYear />
            <Pubblicita />
        </div>
    );
}

export default NewYear;