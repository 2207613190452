import './style/app.css'
import React from "react";
import Pagine from './routes/Pagine.js'


//qui ci deve stare il template, navbar (pagine) e footer
//poi pagine per fare il routing
function App() {
  return (
    <div className="App">
      <Pagine />
    </div>
  );
}

export default App;
