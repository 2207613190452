import "../style/publicita.css";
import React from "react";
import { TOT_BANNER } from "../costanti";
import defaultBanner from "../assets/ads/default.gif";


function Pubblicita() {
    var numero = Math.floor(Math.random() * TOT_BANNER) + 1
    var larghezza = checkWidthDevice()

    return (
        <div className="banner">
            <img 
                src={require(`../assets/ads/banner${numero}.gif`)}
                alt="Banner"
                width={larghezza}
                onError={(e) => {
                    console.log(e)
                    e.target.src = {defaultBanner};
                }}
            />
            {/* <Link to="/info" className="link-footer">
                Info versione
            </Link> */}

        </div>
    );
}

function checkWidthDevice(){
    return window.innerWidth < 768 ? window.innerWidth : 428
}

export default Pubblicita;