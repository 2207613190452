import React from "react";
import '../style/app.css';
import '../style/daily.css';
import Scrivi from '../components/ScriviOroscopo.js'
import Pubblicita from "../components/Pubblicita.js";


function CreaOroscopo() {
    return (
        <div className="crea">
            <h1 style={{padding: "1rem"}}>CREA L'OROSCOPO DI OGGI</h1>
            <Pubblicita/>
            <Scrivi />
        </div>
    );
}

export default CreaOroscopo;