import React from "react";
import '../style/app.css';
import '../style/accedi.css';
import Login from '../components/Login.js'


function Accedi() {
    return (
        <div className="accedi">
            <h1>ACCOUNT</h1>
            <Login />
        </div>
    );
}

export default Accedi;