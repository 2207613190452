import "../style/app.css";
import "../style/daily.css"
import React, { Component } from 'react';
import ButtonElimina from './btnElimina.js';
import Pubblicita from "./Pubblicita.js";
import axios from "axios";
import { HOST } from '../costanti.js';

class ElencoDailyOroscopo extends Component {
       
    constructor(props) {
        super(props);
        this.state = {
            risultati: [],
            bottoneElimina: <div></div>,
            bottone: "Leggi l'oroscopo di ieri",
            ieri: false,
            isAdmin: sessionStorage.getItem("admin"),
            conteggioAdv: 1
        };
    }

    componentDidMount() { 
        this.loadOroscopo()
    }

    componentDidUpdate(_, prevState) {

        // if (this.state.filtro !== prevState.filtro) {
        //     this.loadOroscopo()
        // }
    }


    loadOroscopo(){

        this.setState({
            risultati: []
        })

        var myUrl = HOST + '/getDailyOroscopo.php'

        if (this.state.ieri){
            myUrl = HOST + '/getDailyOroscopo.php?giorno=ieri'
        } 

        axios({
            method: 'GET',
            url: myUrl,
            headers: { 'content-type': 'application/json' }
        })
        .then(response => {
            var rows = []

            if (response.data.return) {

                for (let i = 0; i < response.data.daily.length; i++) {

                    rows.push({ id: response.data.daily[i].id_oroscopo, giorno: response.data.daily[i].giorno, segno: response.data.daily[i].segno, descrizione_lavoro: response.data.daily[i].descrizione_lavoro, descrizione_amore: response.data.daily[i].descrizione_amore, descrizione_recap: response.data.daily[i].descrizione_recap, stelle: response.data.daily[i].stelle, icona: response.data.daily[i].icona })
                }

                if (this.state.ieri){
                    this.setState({
                        bottone: "Leggi l'oroscopo di oggi",
                        risultati: rows,
                        ieri: false
                    })
                } else {
                    this.setState({
                        bottone: "Leggi l'oroscopo di ieri",
                        risultati: rows,
                        ieri: true
                    })
                }
                

            } else {
               
                if (this.state.ieri) {
                    this.setState({
                        bottone: "Leggi l'oroscopo di oggi",
                        ieri: false
                    })
                } else {
                    this.setState({
                        bottone: "Leggi l'oroscopo di ieri",
                        ieri: true
                    })
                }
            }
        })
        .catch(error => alert(error));
    }

    render() {
        if(this.state.risultati.length === 0){
            return (
                <div>
                    <h4>L'oroscopo di oggi non c'è ancora, torna più tardi...</h4>
             

                    <div>
                        <button type="button" className="btnDaily" onClick={() => this.loadOroscopo()}>{this.state.bottone}</button>
                    </div>
                </div>

            );
        } else {
            let conta = 0
            return (
                <div>
                    <div>
                     
                        {
                            
                            this.state.risultati.map((object, index) => {

                                conta += 1
                                return (
                                    <div>
                                    <li key={index}>
                                        <div className="iconaSegno">
                                            <img src={require(`../assets/icons/${object.icona}`)} alt='immagine segno zodiacale' width={64} height={64} />
                                            <span className="segno">{object.segno}</span>
                                            <span className="stelle">{object.stelle} STELLE</span>
                                        </div>
                                        
                                        <div className="divOroscopo">
                                            <p>{object.descrizione_recap}</p>
                                            <div>
                                                <h4>Lavoro</h4>
                                                <p>{object.descrizione_lavoro}</p>
                                            </div>
                                            <div>
                                                <h4>Amore</h4>
                                                <p>{object.descrizione_amore}</p>
                                            </div>
                                        </div> 
                                    
                                
                                        
                                    {
                                    this.state.isAdmin === "true" ? 
                                        <ButtonElimina id={object.id} /> 
                                        : 
                                        <div></div>
                                    }
                                    
                                    </li>
                                    
                                    {
                                    conta % 2 === 0 ?
                                            <Pubblicita /> 
                                            :
                                            <div></div>
                                    }
                                    </div>
                                );

                                }
                            )}

                        <div>
                            <button type="button" className="btnDaily" onClick={() => this.loadOroscopo()}>{this.state.bottone}</button>
                        </div>

                    </div>
                </div>
            );
        }
    } 
        
}

export default ElencoDailyOroscopo;
