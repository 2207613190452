import "../style/footer.css"
import "../style/daily.css"
import React, { Component } from "react"
import { HOST } from '../costanti.js';
import axios from "axios"

class ButtonElimina extends Component{

    eliminaOroscopo() {

        var utente = sessionStorage.getItem("username")

         axios({
             method: 'GET',
             url: HOST + '/eliminaOroscopo.php/?id=' + this.props.id + "&utente=" + utente,
             headers: {
                 'Content-Type': 'application/json',
             },
             data: this.state,
         })
             .then(response => {
                 if (response.data.return) {

                     alert("Oroscopo #" + this.props.id + " cancellato!\nAggiorna per vedere il risultato")
                     
                     //window.location.reload(false);


                 } else {

                     alert("L'oroscopo che hai scelto (#" + this.props.id + ") non è stato cancellato, spiaze")
                 }
             })
             .catch(error => alert(error));

    }
    
    render(){
        return (
            <div>
                <button type='button' className="btnElimina" data-id={this.props.id} onClick={() => this.eliminaOroscopo()}  >ELIMINA</button>
            </div>
        );
    }
    
}

export default ButtonElimina;

