import '../style/app.css'; 
import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../view/Home.js';
import Accedi from '../view/Accedi.js';
import DailyOroscopo from '../view/DailyOroscopo.js'
import CreaOroscopo from '../view/CreaOroscopo.js'
import NewYear from '../view/NewYear.js'
import Info from '../view/Info.js'
import Layout from '../routes/Layout.js'
import Footer from '../components/Footer.js'

const Pagine = () => (
    <BrowserRouter>
    <Layout/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/accedi" element={<Accedi />} />
            <Route path="/daily" element={<DailyOroscopo />}/>
            <Route path="/crea" element={<CreaOroscopo />} />
            <Route path="/info" element={<Info />} />
            <Route path="/newyear" element={<NewYear />} />
        </Routes>
    <Footer/>
    </BrowserRouter>
       

    );


export default Pagine;