import React from "react";
import '../style/app.css';
import '../style/daily.css';
import ElencoDailyOroscopo from '../components/ElencoDailyOroscopo.js'
import Pubblicita from "../components/Pubblicita.js";


function DailyOroscopo() {
    return (
        <div className="daily">
            <h1>DAILY OROSCOPO</h1>
            <ElencoDailyOroscopo />
            <Pubblicita />
        </div>
    );
}

export default DailyOroscopo;