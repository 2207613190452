import React from "react";
import { Link } from 'react-router-dom';
import '../style/app.css';
import '../style/bottoni.css';
import stars from '../assets/img/stars.jpg'
import Pubblicita from '../components/Pubblicita.js'


function Home() {
    return (
        <div className="homepage">
            <h1>LATE OROSCOPO</h1>
            <Pubblicita />
            
            <h4>pagina degli oroscopi in ritardo</h4>

            <div>
                <span className="news">NEW!!</span>
                <Link to="/newyear" style={{ textDecoration: 'none' }}>
                    <button type="button" className="ctaOroscopo ctaOroscopoNewYear">OROSCOPO DEL NUOVO ANNO</button>
                </Link>
                <span className="news">NEW!!</span>
            </div>
            

            <img src={stars} alt="gattini carini" heigth='auto' width='80%' />

            <Link to="/daily" style={{ textDecoration: 'none' }}>
                <button type="button" className="ctaOroscopo">Leggi l'oroscopo di oggi</button>
            </Link>

            <Pubblicita />
        </div>
    );
}

export default Home;