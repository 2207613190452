import { Link } from "react-router-dom";
import React from "react";
import '../style/app.css';
import '../style/navbar.css'
import homePNG from '../assets/icons/home.png'
import accountPNG from '../assets/icons/account.png'
import oroscopoPNG from '../assets/icons/horoscope64.png'

const Layout = () => {
    return (
            <nav>
                 <Link to="/" className="link">
                    <img src={homePNG} alt="HOME" width={32}/>
                 </Link>
                 <Link to="/accedi" className="link">
                    <img src={accountPNG} alt="ACCOUNT" width={32} />
                 </Link>
                 <Link to="/daily" className="link">
                    <img src={oroscopoPNG} alt="OROSCOPO" width={32} />
                 </Link>
            </nav>
    )
};

export default Layout;