import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HOST } from '../costanti.js';
import axios from 'axios';
import '../style/app.css';
import '../style/input.css';
import '../style/accedi.css';
import CheckLogin from './CheckLogin.js';

class ScriviOroscopo extends Component {

    
    constructor(props) {
        super(props);

        this.state = {
            segno: "",
            lavoro: "",
            amore: "",
            recap: "",
            utente: sessionStorage.getItem("username"),
            stelle: "",
            message: "",
            visible: "hidden",
            color: "",
        }
    }

    inviaOroscopo(){

       if(this.state.amore !== "" && 
          this.state.lavoro !== "" && 
          this.state.recap !== "" && 
          this.state.segno !== "" && 
          this.state.stelle !== ""){

           axios({
               method: 'POST',
               url: HOST + '/inviaOroscopo.php',
               headers: {
                   'Content-Type': 'application/json',
               },
               data: this.state,
           })
               .then(response => {
                   if (response.data.return) {

                       console.log("oroscopo inviato")

                       this.setState({
                           message: response.data.message,
                           visible: "visible",
                           color: "#000",
                           segno: "",
                           lavoro: "",
                           amore: "",
                           recap: "",
                           stelle: "",
                       })

                   } else {

                       this.setState({
                           message: response.data.message,
                           visible: "visible",
                           color: "#d90a0a"
                       })
                   }
               })
               .catch(error => this.setState({ message: error.message, visible: "visible", color: "#d90a0a" }));

          } else {
           alert("Per poter inviare l'oroscopo devi prima compilare tutti i campi :(\n")
          }
        
    }

    render(){
        var usernameEsiste = sessionStorage.getItem("username")
        var utenteLoggato = sessionStorage.getItem("isUserLogged")
     

        if (usernameEsiste === null && utenteLoggato === null) {

            return (
                <div>
                    <CheckLogin />
                </div>
            );

        } else {
            
            return (
                <div className="crea-oroscopo">
                    <p>Segno</p>
                    <select className="descrizione" 
                        value={this.state.segno}
                        defaultValue={""}
                        onChange={(e) => this.setState({ segno: e.target.value })}
                        required>
                        <option value=""></option>
                        <option value="ariete">Ariete</option>
                        <option value="toro">Toro</option>
                        <option value="gemelli">Gemelli</option>
                        <option value="cancro">Cancro</option>
                        <option value="leone">Leone</option>
                        <option value="vergine">Vergine</option>
                        <option value="bilancia">Bilancia</option>
                        <option value="scorpione">Scorpione</option>
                        <option value="saggitario">Saggitario</option>
                        <option value="capricorno">Capricorno</option>
                        <option value="acquario">Acquario</option>
                        <option value="pesci">Pesci</option>
                    </select>
                    <p>Descrizione Lavoro (2)</p>
                    <textarea className="descrizione" rows="6" cols="50" placeholder="Lavoro..."
                        value={this.state.lavoro}
                        onChange={(e) => this.setState({ lavoro: e.target.value })}
                        required>
                    </textarea>
                    <p>Descrizione Amore (3)</p>
                    <textarea className="descrizione" rows="6" cols="50" placeholder="Amore..."
                        value={this.state.amore}
                        onChange={(e) => this.setState({ amore: e.target.value })}
                        required>
                    </textarea>

                    <p>Riassunto Giornata (1)</p>
                    <textarea className="descrizione" rows="6" cols="50" placeholder="La giornata di oggi..."
                        value={this.state.recap}
                        onChange={(e) => this.setState({ recap: e.target.value })}
                        required>
                    </textarea>

                    <p>Stelle</p>
                    <select className="descrizione"
                        value={this.state.stelle}
                        defaultValue={''}
                        onChange={(e) => this.setState({ stelle: e.target.value })}
                        required>
                        <option value=""></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    
                    <button type="button" className="btnInvia" onClick={() => this.inviaOroscopo()}>INVIA</button>
                 
                    
                    <p style={{color: this.state.color}}><b>{this.state.message}</b></p>

                    <Link to="/accedi" style={{ textDecoration: 'none' }}>
                        <button type="button" className="btnIndietro">
                            INDIETRO
                        </button>
                    </Link>


                </div>
            );
        }

    }

}
export default ScriviOroscopo;